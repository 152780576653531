var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "router-link",
    {
      staticClass: "search-item",
      attrs: {
        to: { name: "object-detail", params: { objectKey: _vm.objectKey } }
      }
    },
    [
      _vm.item.Status === "Аннулирован" || _vm.item.Status === "Снят с учета"
        ? _c("div", { staticClass: "search-item__badge badge badge-danger" }, [
            _vm._v("\n    " + _vm._s(_vm.item.Status) + "\n  ")
          ])
        : _vm._e(),
      _c("div", { staticClass: "search-item__head" }, [
        _c("div", { staticClass: "search-item__head-text" }, [
          _vm.item.ObjectType
            ? _c("div", { staticClass: "search-item__type mb-2" }, [
                _vm._v("\n        " + _vm._s(_vm.item.ObjectType) + "\n      ")
              ])
            : _vm._e(),
          _c("strong", { staticClass: "search-item__number d-block" }, [
            _vm._v(_vm._s(_vm.item.Number))
          ])
        ]),
        _c("svg", { staticClass: "search-item__object-icon" }, [
          _c("use", { attrs: { "xlink:href": "#icon-area" } })
        ])
      ]),
      _c("div", { staticClass: "search-item__description" }, [
        _vm.item.Name
          ? _c("div", { domProps: { innerHTML: _vm._s(_vm.item.Name) } })
          : _vm._e(),
        _vm._v("\n    " + _vm._s(_vm.item.Address) + "\n  ")
      ]),
      _c(
        "div",
        { staticClass: "object-props search-item__props" },
        [
          _c(
            "app-object-prop",
            { attrs: { title: "Площадь", icon: "#icon-square-meter" } },
            [
              _vm._v("\n      " + _vm._s(_vm.item.Area) + " м"),
              _c("sup", [_vm._v("2")])
            ]
          ),
          _vm.item.YearBuilt
            ? _c("app-object-prop", {
                attrs: {
                  title: "Дата постройки",
                  icon: "#icon-calendar",
                  value: _vm.item.YearBuilt
                }
              })
            : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }