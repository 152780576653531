var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container container_responsive" }, [
    _c(
      "div",
      { staticClass: "page-wrapper search-container wrapper-padding_lg" },
      [
        _c(
          "div",
          {
            staticClass: "navigation-back text-primary d-md-block d-none",
            on: {
              click: function($event) {
                return _vm.$router.back()
              }
            }
          },
          [
            _c("svg", [
              _c("use", { attrs: { "xlink:href": "#icon-navigation-back" } })
            ])
          ]
        ),
        _c("div", { staticClass: "page-wrapper-head" }, [
          _c("div", [
            _c("h4", { staticClass: "page-wrapper-head__title d-inline" }, [
              _vm._v("\n          Результаты поиска\n        ")
            ]),
            _c(
              "span",
              {
                staticClass: "btn-link small",
                on: { click: _vm.toggleSearchInfo }
              },
              [_vm._v("Не нашли объект?")]
            )
          ]),
          _c(
            "div",
            { staticClass: "page-wrapper-head__right" },
            [
              _c("app-object-filter", {
                on: { "filter-change": _vm.filterChange }
              })
            ],
            1
          )
        ]),
        _c(
          "app-collapse-content",
          {
            staticClass: "search-collapse",
            attrs: { visible: _vm.showSearchInfo }
          },
          [
            _c("div", { staticClass: "alert alert-info text-center m-0" }, [
              _vm._v(
                "\n        Мы готовы найти объект за вас. Напишите нам на email\n        "
              ),
              _c(
                "a",
                {
                  staticClass: "alert-link",
                  attrs: { href: "mailto:001@realtycloud.ru" }
                },
                [_vm._v("001@realtycloud.ru")]
              ),
              _vm._v(
                ". Укажите\n        корректный и точный адрес или кадастровый номер. Наши специалисты найдут объект и свяжутся\n        с вами.\n      "
              )
            ])
          ]
        ),
        _vm.loading
          ? _c("div", { staticClass: "text-center" }, [
              _vm._v(
                "\n      Пожалуйста подождите, идет поиск объекта недвижимости\n    "
              )
            ])
          : !_vm.items.length
          ? _c(
              "div",
              { staticClass: "alert alert-info text-center py-4 m-0" },
              [
                _c("p", [_vm._v("К сожалению, ничего не найдено")]),
                _c("p", { staticClass: "mb-0" }, [
                  _vm._v("\n        Попробуйте задать точнее адрес\n      ")
                ])
              ]
            )
          : !_vm.filteredItems.length
          ? _c(
              "div",
              { staticClass: "alert alert-info text-center py-4 m-0" },
              [_vm._v("\n      По текущему фильтру нет объектов\n    ")]
            )
          : _c(
              "div",
              { staticClass: "row" },
              _vm._l(_vm.filteredItems, function(searchItem, i) {
                return _c(
                  "div",
                  { key: i, staticClass: "col-md-6 search-container__col" },
                  [_c("app-search-item", { attrs: { item: searchItem } })],
                  1
                )
              }),
              0
            ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.loading,
                expression: "loading"
              }
            ],
            staticClass: "search-loader"
          },
          [
            _c("app-loader", {
              attrs: {
                color: "white",
                "text-color": "white",
                size: "md",
                text: "Пожалуйста подождите, идет поиск объекта недвижимости"
              }
            })
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }