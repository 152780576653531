var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "object-filter" },
    _vm._l(_vm.filterItems, function(filter, i) {
      return _c(
        "div",
        {
          key: i,
          staticClass: "object-filter__item",
          class: { "object-filter__item_active": i === _vm.activeIndex },
          on: {
            click: function($event) {
              return _vm.changeFilter(i)
            }
          }
        },
        [
          filter.icon
            ? _c("svg", { staticClass: "object-filter__icon" }, [
                _c("use", { attrs: { "xlink:href": "#icon-" + filter.icon } })
              ])
            : _c("span", [_vm._v(_vm._s(filter.name))])
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }