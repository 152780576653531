






















































































import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';

import AppObjectFilter from '@/components/ObjectFilter/ObjectFilter.vue';
import AppSearchItem from './SearchItem.vue';

@Component({
  components: { AppSearchItem, AppObjectFilter },
  computed: {
    ...mapState('search', [ 'loading', 'query', 'items' ]),
  },
})
export default class SearchComponent extends Vue {
  // computed types
  items!: RealtyObject[];
  loading!: boolean;
  query!: string;

  // data() {
  showSearchInfo: boolean = false;

  // computed
  get filteredItems(): RealtyObject[] {
    return this.$store.getters['search/filteredItems'];
  }

  // lifecycle
  created() {
    const urlSearchQuery = this.$route.query.q;

    if (urlSearchQuery && urlSearchQuery !== this.query) {
      this.$store.commit('search/setQuery', urlSearchQuery);

      if (!this.loading) {
        this.$store.dispatch('searchObjects', urlSearchQuery);
      }
    }
  }

  // methods
  filterChange(filterName: string) {
    this.$store.commit('search/changeFilter', filterName);
  }

  toggleSearchInfo() {
    this.showSearchInfo = !this.showSearchInfo;
  }
}
