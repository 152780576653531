



















































import Vue from 'vue';

export default Vue.extend({
  props: {
    item: Object as () => RealtyObject,
  },

  computed: {
    objectKey(): string | number {
      if (this.item.Number) {
        return this.item.Number.replace(/\s+/g, '');
      }

      const address = this.item.Address && encodeURIComponent(this.item.Address);

      return address || '';
    },
  },
});
