



















import Vue from 'vue';

export default Vue.extend({
  name: 'AppObjectFilter',

  data() {
    return {
      activeIndex: 0,
      filterItems: [
        {
          name: 'Все',
        },
        {
          name: 'Прочее',
        },
        {
          icon: 'build',
          name: 'Здание',
        },
        {
          icon: 'area',
          name: 'Участок',
        },
        {
          icon: 'room',
          name: 'Помещение',
        },
      ],
    };
  },

  methods: {
    changeFilter(index: number) {
      if (index === this.activeIndex) {
        return;
      }

      this.activeIndex = index;
      this.$emit('filter-change', this.filterItems[index].name);
    },
  },
});
